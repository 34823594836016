import React, { useContext } from 'react';
import * as S from './SavingsPopupContent.styles';
import FunnelContext from '../../context/FunnelContext';

const SavingsPopupContent = () => {
  const context = useContext(FunnelContext);
  const {
    onetime,
    subscription,
    showSavingsPopup,
    setShowSavingsPopup
  } = context;

  const Box = ({ quantity, index }) => {
    const subProduct = subscription.products[index];
    const otpProduct = onetime.products[index];

    const subTubPrice =
      subProduct?.checkoutData?.discounted_price.toFixed(2) ?? `00.00`;
    const otpTubPrice =
      otpProduct?.checkoutData?.discounted_price.toFixed(2) ?? `00.00`;

    const subChewPrice = (subTubPrice / (quantity * 30)).toFixed(2);
    const otpChewPrice = (otpTubPrice / (quantity * 30)).toFixed(2);

    return (
      <S.Box>
        <S.Table>
          <tbody>
            <S.Tr>
              <S.Td className="left">
                {quantity} x{' '}
                <img alt={`${quantity} Tub`} src="/images/tub-icon.svg" />
              </S.Td>
              <S.Td className="right">
                <S.TubPrice>${subTubPrice}</S.TubPrice>
                <S.ChewPrice>${subChewPrice} per chew</S.ChewPrice>
              </S.Td>
              <S.Td className="right">
                <S.TubPrice>${otpTubPrice}</S.TubPrice>
                <S.ChewPrice>${otpChewPrice} per chew</S.ChewPrice>
              </S.Td>
            </S.Tr>
          </tbody>
        </S.Table>
      </S.Box>
    );
  };

  if (showSavingsPopup) {
    return (
      <>
        <S.Container>
          <S.CloseButton onClick={() => setShowSavingsPopup(false)}>
            X
          </S.CloseButton>
          <S.Title>Savings</S.Title>
          <S.Description>
            The more you add, the more you save; and when you subscribe, you
            save even more! Check out how much you can save:
          </S.Description>

          <S.Table>
            <tbody>
              <S.Tr>
                <S.Td className="left nopadding">Quantity</S.Td>
                <S.Td className="nopadding">Subscribe</S.Td>
                <S.Td className="right  nopadding">Buy Once</S.Td>
              </S.Tr>
            </tbody>
          </S.Table>

          {[1, 2, 3, 4].map((quantity, index) => (
            <Box key={index} index={index} quantity={quantity} />
          ))}
        </S.Container>
        <S.Wrapper />
      </>
    );
  } else {
    return ``;
  }
};

export default SavingsPopupContent;
