import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  max-width: 380px;
  min-height: 493px;
  z-index: 9999;
  top: calc(50% - calc(493px / 2));
  left: calc(50% - 190px);
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 2px;
  padding: 30px;

  @media (max-width: 375px) {
    max-width: 340px;
    left: calc(50% - 170px);
    min-height: 310px;
  }
  @media (max-width: 320px) {
    max-width: 300px;
    left: calc(50% - 150px);
    min-height: 320px;
  }
`;

export const Title = styled.h3`
  margin: 0 auto;
  max-width: 120px;
  display: block;
  background-image: url(/images/dollar-bag.svg);
  background-repeat: no-repeat;
  padding: 5px;
  padding-top: 10px;
  padding-left: 40px;
`;

export const Description = styled.div`
  font-size: 14px;
  position: relative;
  text-align: center;
  border-bottom: 1px solid #8f9ea6;
  padding: 20px 0;
  margin-bottom: 20px;
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #e26447;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  padding-top: 17px;
  padding-left: 1px;
  position: absolute;
  line-height: 0;
  right: -10px;
  top: -15px;
  cursor: pointer;
`;

export const Box = styled.div`
  border: 1px solid #8f9ea6;
  border-radius: 2px;
  margin-top: 10px;
  & > table {
    margin-top: 0;
  }
  padding-left: 5px;
  padding-right: 5px;
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 15px;
`;

export const Tr = styled.tr``;
export const Td = styled.td`
  text-align: center;
  vertical-align: center;
  padding: 10px 0;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  &.nopadding {
    padding: 0;
  }
`;

export const TubPrice = styled.span`
  display: block;
  font-size: 14px;
  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #BC2E3E!important;
                `;
      default:
        return `
                    color: #e26447!important;
                `;
    }
  }}
`;
export const ChewPrice = styled.span`
  display: block;
  font-size: 12px;
`;
